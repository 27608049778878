body.unaccepted-content-advice main.page-content {
  display: none;
}

body.accepted-content-advice div.content-advice-wrapper {
  display: none;
}

body.unaccepted-content-advice div.content-advice-restore {
  display: none;
}

body.accepted-content-advice div.content-advice-restore {
  display: block;
}

div.content-advice-restore {
	position: relative;
	width: 100%;
	text-align: center;

	button {
		margin: 1ex;
	}
}

div.content-advice-wrapper {
  /* min-width: calc(#{$content-width} - (#{$spacing-unit} * 2)); */
}

div.content-advice-container {
  background: repeating-linear-gradient(
    135deg,
    #ffefef,
    #ffefef 10px,
    #ffffff 10px,
    #ffffff 20px
  );

  text-shadow: 2px 2px 0px rgba(255, 255, 255, 100);
  border: #ff7979 solid 3px;
  border-radius: 5px;
  padding: $spacing-unit / 5;
  margin-top: $spacing-unit / 2;
  margin-bottom: $spacing-unit / 2;
}

span.content-warning {
  font-weight: bold;
  font-size: larger;
  vertical-align: middle;
  display: block;
  margin: 0;
}

div.content-advice {
  margin-top: $spacing-unit / 3;
  margin-left: 1em;
  margin-right: 1em;
  display: none;
}

/* Content advice controls */

a.show-content-advice {
  cursor: pointer;
}

a.show-content-advice:hover {
  text-decoration: none;
}

.content-advice.visible {
  display: block;
}

.content-advice-options,
.content-advice-restore {
  button {
    border-style: solid;
    border-width: 3px;
    border-radius: 5px;
    flex: 1;
    padding: $spacing-unit / 5;
    margin-bottom: $spacing-unit / 5;
  }

  button:first-child {
    margin-right: $spacing-unit / 5;
  }

  #content-advice-return,
  #content-advice-restore {
    flex: 2;
    border-color: #ff7979;
    background-color: #ffefef;
  }

  #content-advice-proceed {
    border-color: #79ff79;
    background-color: #efffef;
  }
}

.content-advice-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Link content tags */

a.content-warning {
	/* background-color: #ffefef; */
}

.content-tag {
  display: inline-block;
  vertical-align: baseline;
  float: right;
}

.nsfw-tag {
  color: #e39090;
}

.content-advice-tag {
  color: #a690e3;
}
