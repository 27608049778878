div.story-navigation {
  display: flex;
  flex-direction: row;

  .nav-link {
    flex: 1;
  }

  .contents {
    text-align: center;
  }

  .next-chapter {
    text-align: right;
  }
}
